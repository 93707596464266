function calculateDistance(lat1, lon1, lat2, lon2) {
  const toRadians = (degrees) => (degrees * Math.PI) / 180;

  const R = 6371; // Radius of the Earth in kilometers
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) ** 2 +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) ** 2;

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in kilometers
}

export function calculateDeviation(storeCoords, userLocation, maxThreshold = 0.10) {
  const [storeLon, storeLat] = storeCoords;
  const { latitude: userLat, longitude: userLon } = userLocation;

  console.log(userLocation)

  // Calculate distance between store and user in kilometers
  const distance = calculateDistance(storeLat, storeLon, userLat, userLon);


  const position = {}
  if (isNaN(distance)) {
    position.status = 'N/A'
    position.color = 'black'
  } else if (distance > maxThreshold) {
    position.status = 'out of coverage'
    position.color = 'red'
  } else {
    position.status = 'in coverage'
    position.color = 'green'
  }


  return position;
}

// // Example usage
// const storeCoordinates = [3.3653539, 6.4984191]; // [longitude, latitude]
// const userLocation = { latitude: 6.04, longitude: 3.03 };

// const deviationPercentage = calculateDeviation(storeCoordinates, userLocation);
// console.log(`User is ${deviationPercentage.toFixed(2)}% far from the store location.`);

