import { Box, Flex } from "@chakra-ui/react"
import Sidebar from "../../components/sidebar/sidebar";
import Navbar from "../../components/navbar/navbar";
import { useSelector } from "react-redux";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from './dashboard.module.css'
import UtilityContext from "context/utilityContext";

const Dashboard = ({ collapsed, setCollapsed, component }) => {
  // const currentUser = useSelector(({userData})=>  userData?.currentUser);
  // const navigate = useNavigate()

  // useEffect(() => {
  //   if(currentUser?.role === 'Client') {
  //     navigate('/initiatives')
  //   }
  // }, [currentUser])

  const { downloading, downloadCount, totalImagesToDownload, cancelDownload } = useContext(UtilityContext)

  return (
    <div className={classes.parent}>
      {downloading && (
        <div className={classes.progressContainer}>
          <p>Downloaded: {downloadCount}/{totalImagesToDownload}</p>
          <p>Progress: {downloadCount <= 0 ? 'preparing download...' : `${totalImagesToDownload > 0 ? ((downloadCount * 100) / totalImagesToDownload).toFixed(2) : 0}%`}</p>

          {/* Progress Bar */}
          <div style={{ position: 'relative', height: '10px', background: '#ddd', overflow: 'hidden', width: '100%' }} className={classes.progressBar}>
            <div
              style={{
                height: '100%',
                width: `${totalImagesToDownload > 0 ? (downloadCount / totalImagesToDownload) * 100 : 0}%`,
                backgroundColor: '#4caf50',
                transition: 'width 0.5s ease-in-out',
              }}
            />
          </div>

          {downloading && <button onClick={cancelDownload} style={{ fontWeight: 'bold', color: 'red' }}>Cancel Download</button>}

        </div>
      )}

      <div className={classes.container}>
        <Sidebar collapsed={true} setCollapsed={setCollapsed} />
        <div style={{ width: '100%' }}>
          <Navbar collapsed={collapsed} />
          <div className={classes.componentContainer}>
            {component}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard;