import axios from "axios";
import { defaultDrawerState } from "../constants/defaultStates";
import UtilityContext from "./utilityContext";
import { useRef, useState } from "react";



const UtilityState = ({ children }) => {

    const [drawerProps, setDrawerProps] = useState(defaultDrawerState)

    const draw = ({ title, content }) => {
        setDrawerProps({ title, content })

    }

    const closeDrawer = () => {
        setDrawerProps(defaultDrawerState)
    }



    const [downloading, setDownloading] = useState(false);
    const [downloadCount, setDownloadCount] = useState(0);
    const [totalImagesToDownload, setTotalImagesToDownload] = useState('')


    // Cache for previously fetched addresses
    const addressCache = new Map();

    const fetchAddress = async (latitude, longitude) => {
        const key = `${latitude},${longitude}`;
        if (addressCache.has(key)) return addressCache.get(key);

        try {
            const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`);
            const address = response.data.display_name;
            addressCache.set(key, address); // Store in cache
            return address;
        } catch (error) {
            console.error("Error fetching address:", error);
            return "Unknown Location";
        }
    };

    const abortControllerRef = useRef(null); // Store the AbortController instance


    const cancelDownload = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort(); // Cancel request
            setDownloading(false);
        }
    };



    const [currentCountry, setCurrentCountry] = useState('')

    const fetchCurrentCountry = async () => {
        try {
            const response = await axios.get('https://ipapi.co/json/');
            const country = response.data.country_name;

            console.log(`You are currently in: ${country}`);
            setCurrentCountry(country)
            return country;
        } catch (error) {
            console.error('Error fetching current country:', error);
            return null;
        }
    };




    return (
        <UtilityContext.Provider
            value={{
                draw, drawerProps, closeDrawer,
                downloading, setDownloading,
                downloadCount, setDownloadCount,
                fetchAddress, totalImagesToDownload, setTotalImagesToDownload,
                abortControllerRef, cancelDownload, fetchCurrentCountry, currentCountry, setCurrentCountry
            }}
        >
            {children}
        </UtilityContext.Provider>
    );
};
export default UtilityState;