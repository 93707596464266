/* eslint-disable react-hooks/exhaustive-deps */
import { InitiativeQueryComponent } from "components/initiativeQueryComponent/initiativeQueryComponent";
import { defaultSearchValueState } from "constants/defaultStates";
import { useEffect, useState } from "react";
import classes from './assetsComponents.module.css'
import { Loader } from "components/Loader/loader";
import axios from "axios";
import { formatDateHeader } from "helpers/formatDateHeader";
import moment from "moment";
import { InitiativePaginationComponent } from "components/initiativePaginationComponent/initiativePaginationComponent";
import { InitiativeEmptyState } from "components/initiativeEmptyState/initiativeEmptyState";
import { calculateDeviation } from "helpers/Haversine";
import { CustomSelect } from "./customSelect";
import { IoPinSharp } from "react-icons/io5";
import { MdStorefront } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { numberFormat } from "helpers/numberFormatter";
import { GiLevelTwoAdvanced } from "react-icons/gi";
import MapWithMarkers from "components/mapWIthMarkers/mapWithMarkers";



const url = process.env.REACT_APP_BASE_URL;

export function AssetMSL({ clientId, draw, brandGroups,storeGroups }) {
    const [searchValues, setSearchValues] = useState(defaultSearchValueState);
    const [loading, setLoading] = useState(false);
    const [showMap, setShowMap] = useState(null)

    const [docs, setDocs] = useState(null)
    const [inputPage, setInputPage] = useState(searchValues.page);

    const limit = 100


    const getDocs = async () => {
        setLoading(true);

        const token = localStorage.getItem('PRTK');
        const config = {
            headers: { authorization: `Bearer ${token}` },
        }

        try {
            const res = await axios.get(`${url}/assets/MSLs/client/all?clientId=${clientId}&search=${searchValues?.searchQuery}&startDate=${searchValues?.startDate || ''}&endDate=${searchValues?.endDate ? searchValues?.endDate : ''}&limit=${limit}&page=${searchValues.page}&brandGroup=${searchValues?.['asset group'] || ''}&storeGroup=${searchValues?.['store group'] || ''}`, config);
            setDocs(res.data.data);

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }





    useEffect(() => {
        getDocs();
    }, [searchValues]);



    return (
        <div >


            {
                showMap &&
                <div className={classes.showMapParent}>
                    <div className={classes.showMapSecondParent}>
                        <MapWithMarkers singleMarker={showMap} />
                        <button onClick={() => { setShowMap(null) }} className={classes.closeMap}>Close Map</button>
                    </div>
                </div>
            }

            <InitiativeQueryComponent
                searchValues={searchValues}
                setSearchValues={setSearchValues}
                placeholder={'search asset MSL scores'}
                customSelect={
                    <div className={classes.customerSelectContainer}>
                    <CustomSelect type={'asset group'} groupList={brandGroups} searchValues={searchValues} setSearchValues={setSearchValues} />
                    <CustomSelect type={'store group'} groupList={storeGroups} searchValues={searchValues} setSearchValues={setSearchValues} />
                </div>
                }
            />
            {loading ? (
                <div className={classes.loader_con}>
                    <Loader />
                </div>
            ) : (
                <>
                    {docs?.assetsMSLs?.length ? (
                        <div className={classes.table_container}>
                            <div className={classes.titleAndInstructionContainer}>
                                <p className={classes.title}>Total Asset MSL Scores Found: {docs?.totalCount}</p>
                                <p className={classes.instruction}>
                                    Click on each row to see more{" "}
                                </p>
                            </div>

                            <p className={classes.showing}>
                                Showing:{" "}
                                <span>
                                    {docs?.assetsMSLs?.length || 1} of {docs?.totalCount}
                                </span>
                            </p>
                            <div className={classes.scrollable}>
                                <table className={classes.table}>
                                    <thead>
                                        <tr>
                                            <th>Creation Date</th>
                                            <th>Brand</th>
                                            <th>Category</th>
                                            <th>Type</th>
                                            <th>Entered By</th>
                                            <th>Outlet</th>
                                            <th>MSL</th>
                                            <th>Location</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {docs?.assetsMSLs?.map((item, index) => (
                                            <tr
                                                onClick={() =>
                                                    draw({
                                                        title: "Assets MSL Score",
                                                        content: (
                                                            <div className={classes.modalContainer}>
                                                                <div
                                                                    className={classes.modalTitleAndDateContainer}
                                                                >
                                                                    <p className={classes.modalTitle}>
                                                                        {item?.asset?.brand || 'from a deleted asset'} - {item?.asset?.category || 'from a deleted asset'} -{item?.asset?.type || 'from a deleted asset'}
                                                                    </p>
                                                                    <p>
                                                                        {formatDateHeader(
                                                                            moment(item.updatedAt).format('YYYY-MM-DD')
                                                                        )}
                                                                    </p>
                                                                </div>


                                                                <div
                                                                    className={classes.modalStoreAddressContainer}
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        setShowMap(item.location)
                                                                    }}
                                                                >
                                                                    <p
                                                                        className={`${classes.modalStoreAddress} ${classes.item_text}`}
                                                                        style={{ color: calculateDeviation((item?.asset?.outlet ? item?.asset?.outlet?.location?.coordinates : [item?.asset?.addressDetails?.coordinates?.longitude, item?.asset?.addressDetails?.coordinates?.latitude]), item?.location).color }}
                                                                    >
                                                                        {calculateDeviation((item?.asset?.outlet ? item?.asset?.outlet?.location?.coordinates : [item?.asset?.addressDetails?.coordinates?.longitude, item?.asset?.addressDetails?.coordinates?.latitude]), item?.location).status}
                                                                    </p>
                                                                    <IoPinSharp className={classes.modalIcon} />
                                                                </div>

                                                                <div
                                                                    className={classes.modalStoreAddressContainer}
                                                                >
                                                                   <p className={classes.modalStoreAddress}>
                                                                        {item?.asset?.outlet?.name || item?.asset?.addressDetails?.locationName}, {item?.asset?.outlet?.streetName || item?.asset?.addressDetails?.streetName}, {item?.asset?.outlet?.state || item?.asset?.addressDetails?.state}, {item?.asset?.outlet?.country || item?.asset?.addressDetails?.country}
                                                                    </p>
                                                                    <MdStorefront className={classes.modalIcon} />
                                                                </div>

                                                                <div
                                                                    className={classes.modalStoreAddressContainer}
                                                                >
                                                                    <div style={{ width: "90%" }}>
                                                                        <p className={classes.modalStoreAddress}>
                                                                            {item?.enteredBy?.name
                                                                                ? item?.enteredBy?.name
                                                                                : `${item?.enteredBy?.firstName} ${item?.enteredBy?.lastName || ""
                                                                                }`}
                                                                        </p>

                                                                    </div>

                                                                    <FaRegUser className={classes.modalIcon} />
                                                                </div>

                                                                <div
                                                                    className={classes.modalStoreAddressContainer}
                                                                >
                                                                    <div style={{ width: "90%" }}>
                                                                        <p className={classes.modalStoreAddress}>
                                                                            {numberFormat.format(item.mslLevel)}
                                                                        </p>
                                                                        <p className={classes.messageDescription}>
                                                                            MSL Score
                                                                        </p>
                                                                    </div>

                                                                    <GiLevelTwoAdvanced
                                                                        className={classes.modalIcon}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ),
                                                    })
                                                }
                                                key={index}
                                            >
                                                <td className={classes.tooltip_container}>
                                                    <p className={classes.item_text}>
                                                        {formatDateHeader(
                                                            moment(item.updatedAt).format('YYYY-MM-DD')
                                                        )}
                                                    </p>
                                                    <p className={classes.tooltip}>
                                                        {formatDateHeader(
                                                            moment(item.updatedAt).format('YYYY-MM-DD')
                                                        )}
                                                    </p>
                                                </td>

                                                <td className={classes.tooltip_container}>
                                                    <p className={classes.item_text}>{item?.asset?.brand || 'from a deleted asset'}</p>
                                                    <p className={classes.tooltip}>{item?.asset?.brand || 'from a deleted asset'}</p>
                                                </td>
                                                <td className={classes.tooltip_container}>
                                                    <p className={classes.item_text}>{item?.asset?.category || 'from a deleted asset'}</p>
                                                    <p className={classes.tooltip}>{item?.asset?.category || 'from a deleted asset'}</p>
                                                </td>
                                                <td className={classes.tooltip_container}>
                                                    <p className={classes.item_text}>{item?.asset?.type || 'from a deleted asset'}</p>
                                                    <p className={classes.tooltip}>{item?.asset?.type || 'from a deleted asset'}</p>
                                                </td>
                                                <td className={classes.tooltip_container}>
                                                    <p className={classes.item_text}>
                                                        {`${item.enteredBy?.firstName} ${item.enteredBy?.lastName}`}
                                                    </p>
                                                    <p className={classes.tooltip}>
                                                        {`${item.enteredBy?.firstName} ${item.enteredBy?.lastName}`}
                                                    </p>
                                                </td>
                                                <td className={classes.tooltip_container}>
                                                    <p className={classes.item_text}>
                                                        {item?.asset?.outlet?.name || `${item?.asset?.addressDetails?.buildingNo || 'No Bld no.'}, ${item?.asset?.addressDetails?.streetName}`}
                                                    </p>
                                                    <p className={classes.tooltip}>
                                                        {item?.asset?.outlet?.name || `Registered on Field by ${item?.fieldRegisterer?.firstName} ${item?.fieldRegisterer?.lastName}`} - {""}
                                                        {item?.outlet?.streetNumber ||
                                                            item?.addressDetails?.buildingNo ||
                                                            "No Bld. num."}
                                                        , {item?.outlet?.streetName || item?.addressDetails?.streetName}, {item?.outlet?.town || item?.addressDetails?.city}, {item?.outlet?.area || item?.addressDetails?.LGA}, {item?.outlet?.state || item?.addressDetails?.state}
                                                    </p>
                                                </td>

                                                <td className={classes.tooltip_container}>
                                                    <p className={classes.item_text}>
                                                        {item.mslLevel}
                                                    </p>
                                                    <p className={classes.tooltip}>
                                                        {item.mslLevel}
                                                    </p>
                                                </td>


                                                <td className={classes.tooltip_container}>
                                                    <p
                                                        className={classes.item_text}
                                                        style={{ color: calculateDeviation((item?.asset?.outlet ? item?.asset?.outlet?.location?.coordinates : [item?.asset?.addressDetails?.coordinates?.longitude, item?.asset?.addressDetails?.coordinates?.latitude]), item?.location).color }}
                                                    >
                                                        {calculateDeviation((item?.asset?.outlet ? item?.asset?.outlet?.location?.coordinates : [item?.asset?.addressDetails?.coordinates?.longitude, item?.asset?.addressDetails?.coordinates?.latitude]), item?.location).status}
                                                    </p>

                                                    <p className={classes.tooltip}>
                                                        {calculateDeviation((item?.asset?.outlet ? item?.asset?.outlet?.location?.coordinates : [item?.asset?.addressDetails?.coordinates?.longitude, item?.asset?.addressDetails?.coordinates?.latitude]), item?.location).status}
                                                    </p>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <InitiativePaginationComponent
                                page={searchValues.page}
                                totalCount={docs?.totalCount}
                                setSearchValues={setSearchValues}
                                setInputPage={setInputPage}
                                inputPage={inputPage}
                                limit={limit}
                            />
                        </div>
                    ) : (
                        <InitiativeEmptyState searchValues={searchValues} name={"assets MSL score"} />
                    )}
                </>
            )}

        </div>
    )
}