/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import classes from './assetsComponents.module.css';
import axios from 'axios';
import _ from 'lodash'
import { IoMdClose } from 'react-icons/io';


const url = process.env.REACT_APP_BASE_URL;

export default function AvailableForRegistration({ clientId, showToast }) {
    const [data, setData] = useState([]);
    const [brand, setBrand] = useState('');
    const [category, setCategory] = useState('');
    const [type, setType] = useState('');
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [initialData, setInitialData] = useState([]);
    const [loadingNames, setLoadingNames] = useState(false);


    const getNames = async () => {
        const token = localStorage.getItem('PRTK');
        const config = {
            headers: { authorization: `Bearer ${token}` },
        };
        setLoadingNames(true);

        try {
            const response = await axios.get(`${url}/assets/fetch/pre-name?client=${clientId}&sort=+brand`, config);
            const fetchedData = response?.data?.data?.names || [];
            setData(fetchedData);
            setInitialData(fetchedData);
        } catch (error) {
            const message = error?.response?.data?.message || 'Something went wrong';
            showToast(message, 'error');
        } finally {
            setLoadingNames(false);
        }
    };

    const [submitting, setSubmitting] = useState(false)



    const submitData = async () => {

        if (!hasChanges) {
            showToast('No changes to submit.', 'info');
            return;
        }


        const token = localStorage.getItem('PRTK');
        const config = {
            headers: {
                authorization: `Bearer ${token}`,
                'Content-type': 'application/json',
            },
        };

        // Filter out unchanged objects
        const alteredData = data.filter((newItem) => {
            const originalItem = initialData.find((oldItem) => oldItem.brand === newItem.brand);

            // Compare the current item with the original one deeply
            return !originalItem || !_.isEqual(newItem, originalItem);
        });



        if (alteredData.length === 0) {
            showToast('No meaningful changes to submit.', 'info');
            return;
        }

        try {
            const sanitizedData = alteredData.map(({ brand, categories }) => ({
                brand,
                categories: categories.map(({ category, types }) => ({
                    category,
                    types,
                })),
            }));

            setSubmitting(true)

            const payload = {
                client: clientId,
                namesArray: sanitizedData,
            };

            await axios.post(`${url}/assets/pre-name`, payload, config);
            showToast('Data saved successfully!', 'success');
            setInitialData(data); // Update initial data to reflect the submitted state
            setSubmitting(false)

        } catch (error) {
            const message = error?.response?.data?.message || 'Failed to save data';
            showToast(message, 'error');
        } finally {
            setSubmitting(false)

        }
    };


    const addBrands = () => {
        if (brand) {
            const brands = brand.split(',').map((b) => b.trim());
            const updatedData = [...data];
            brands.forEach((b) => {
                if (!updatedData.some((item) => item.brand === b)) {
                    updatedData.push({ brand: b, categories: [] });
                }
            });
            setData(updatedData);
            setBrand('');
        }
    };

    const addCategories = () => {
        if (selectedBrand && category) {
            const categories = category.split(',').map((c) => c.trim());
            const updatedData = data?.map((item) => {
                if (item.brand === selectedBrand) {
                    const existingCategories = item.categories.map((cat) => cat.category);
                    const newCategories = categories.filter((c) => !existingCategories.includes(c));
                    return {
                        ...item,
                        categories: [
                            ...item.categories,
                            ...newCategories.map((c) => ({ category: c, types: [] })),
                        ],
                    };
                }
                return item;
            });
            setData(updatedData);
            setCategory('');
        }
    };

    const addTypes = () => {
        if (selectedBrand && selectedCategory && type) {
            const types = type.split(',').map((t) => t.trim());
            const updatedData = data?.map((item) => {
                if (item.brand === selectedBrand) {
                    return {
                        ...item,
                        categories: item.categories.map((cat) => {
                            if (cat.category === selectedCategory) {
                                const updatedTypes = [...new Set([...cat.types, ...types])];
                                return { ...cat, types: updatedTypes };
                            }
                            return cat;
                        }),
                    };
                }
                return item;
            });
            setData(updatedData);
            setType('');
        }
    };

    const hasChanges = JSON.stringify(data) !== JSON.stringify(initialData);



    useEffect(() => {
        getNames();
    }, []);


    const deleteBrand = async (brandToDelete, id) => {
        if (window.confirm(`Are you sure you want to delete the brand "${brandToDelete}"?`)) {
        const updatedData = data.filter((item) => item.brand !== brandToDelete);
        setData(updatedData);

        const token = localStorage.getItem('PRTK');
        const config = {
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        };

        try {
            await axios.delete(`${url}/assets/delete/pre-name/${id}`, config);
            showToast('pre named asset removed successfully!', 'success');
        } catch (error) {
            console.error(error)
            const message = error?.response?.data?.message || 'Something went wrong';
            showToast(message, 'error');
        }
        }
    };

    const deleteCategory = (brandName, categoryToDelete) => {
        const updatedData = data.map((item) => {
            if (item.brand === brandName) {
                return {
                    ...item,
                    categories: item.categories.filter((cat) => cat.category !== categoryToDelete),
                };
            }
            return item;
        });
        setData(updatedData);
    };

    const deleteType = (brandName, categoryName, typeToDelete) => {
        const updatedData = data.map((item) => {
            if (item.brand === brandName) {
                return {
                    ...item,
                    categories: item.categories.map((cat) => {
                        if (cat.category === categoryName) {
                            return {
                                ...cat,
                                types: cat.types.filter((type) => type !== typeToDelete),
                            };
                        }
                        return cat;
                    }),
                };
            }
            return item;
        });
        setData(updatedData);
    };


    return (
        <div className={classes.container}>
            {loadingNames ? (
                <p>Loading...</p>
            ) : (
                <>
                    {/* Add Brand */}
                    <div className={classes.availableForRegContainer}>
                        <h3 className={classes.label}>Add Brands</h3>

                        <div className={classes.availableForRegInputContainer}>
                            <input
                                type="text"
                                placeholder="Brand Names (comma-separated)"
                                value={brand}
                                onChange={(e) => setBrand(e.target.value)}
                                className={`${classes.input} ${classes.availableForRegInput}`}
                            />
                            <button className={classes.addBrandButton} onClick={addBrands}>
                                Add
                            </button>
                        </div>
                    </div>
                    {data.length > 0 && (
                        <>
                            <div>
                                <h3 className={classes.label}>Add Categories</h3>

                                <div className={classes.brandSelectContainer}>
                                    <select
                                        className={`${classes.input} ${classes.selectType}`}
                                        value={selectedBrand}
                                        onChange={(e) => setSelectedBrand(e.target.value)}
                                    >
                                        <option value="">Select Brand</option>
                                        {data?.map((item) => (
                                            <option key={item.brand} value={item.brand}>
                                                {item.brand}
                                            </option>
                                        ))}
                                    </select>
                                </div>


                                <div className={classes.availableForRegInputContainer}>
                                    <input
                                        className={`${classes.input} ${classes.selectType}`}
                                        type="text"
                                        placeholder="Categories (comma-separated)"
                                        value={category}
                                        onChange={(e) => setCategory(e.target.value)}
                                    />
                                    <button className={classes.addBrandButton} onClick={addCategories}>
                                        Add
                                    </button>
                                </div>
                            </div>
                            {selectedBrand && (
                                <div>
                                    <h3 className={classes.label}>Add Types</h3>
                                    <div className={classes.brandSelectContainer}>
                                        <select
                                            className={`${classes.input} ${classes.selectType}`}
                                            value={selectedCategory}
                                            onChange={(e) => setSelectedCategory(e.target.value)}
                                        >
                                            <option value="">Select Category</option>
                                            {data
                                                .find((item) => item.brand === selectedBrand)
                                                ?.categories.map((cat) => (
                                                    <option key={cat.category} value={cat.category}>
                                                        {cat.category}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>


                                    <div className={classes.availableForRegInputContainer}>
                                        <input
                                            className={`${classes.input} ${classes.selectType}`}
                                            type="text"
                                            placeholder="Types (comma-separated)"
                                            value={type}
                                            onChange={(e) => setType(e.target.value)}
                                        />
                                        <button className={classes.addBrandButton} onClick={addTypes}>
                                            Add
                                        </button>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    {hasChanges && (
                        <button className={classes.submitButton} disabled={submitting} onClick={submitData}>
                            Submit {submitting && 'ing...'}
                        </button>
                    )}
                    {/* Display the Data */}
                    <div>
                        <h3 className={classes.label}>Data Preview</h3>
                        <table className={classes.table}>
                            <thead>
                                <tr>
                                    <th>Brand</th>
                                    <th>Category</th>
                                    <th>Types</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((item) =>
                                    item?.categories?.map((category, idx) => (
                                        <tr key={`${item.brand}-${category.category}-${idx}`}>
                                            <td className={classes.tooltip_container}>
                                                {idx === 0 && (
                                                    <>
                                                        <p className={classes.item_text}>
                                                            {item.brand}
                                                            <IoMdClose onClick={() => deleteBrand(item.brand, item._id)} className={classes.delete} />
                                                        </p>
                                                    </>
                                                )}
                                            </td>

                                            <td className={classes.tooltip_container}>
                                                <p className={classes.item_text}>{category.category}
                                                    <IoMdClose onClick={() => deleteCategory(item.brand, category.category)} className={classes.delete} />
                                                </p>


                                            </td>

                                            <td className={classes.tooltip_container}>
                                                {category.types.map((type) => (
                                                    <div
                                                        key={type}

                                                        className={classes.tableTextCon}
                                                    >
                                                        {type}

                                                        <IoMdClose onClick={() =>
                                                            deleteType(item.brand, category.category, type)
                                                        } className={classes.delete} />

                                                    </div>
                                                ))}
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>

                </>
            )}
        </div>
    );
}
