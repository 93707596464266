/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Flex, Menu, MenuButton, MenuItem, MenuList, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import CustomDrawer from '../../../../components/customDrawer/customDrawer'
import axiosInstance from '../../../../services/api'
import EmptyState from '../../../../components/emptyState/emptyState'
import PrimaryButton from '../../../../components/primaryButton/primarybutton'
import LineSkeleton from '../../../../components/lineSkeleton'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { buildResourceURL, buildResourceURL2, formatDate, truncateText } from '../../../../utils/helper'
import CustomPagination from '../../../../components/customPagination/customPagination'
import ServerExport from '../../../../components/serverExport/serverExport'
import GoBack from '../../../../components/goBack'
import { MdVerified } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import SpinLoader from '../../../../components/spinLoader/spinLoader'
import ViewResponse from './viewResponse/viewResponse'
import CustomSelect from '../../../../components/customSelect/customSelect'
import Select from 'react-select';
import SearchableSelect from '../../../../components/searchableSelect/searchableSelect'
import { useSelector } from 'react-redux'
import CustomInput from '../../../../components/customInput/customInput'
import MapWithMarkers from 'components/mapWIthMarkers/mapWithMarkers'
import { InitiativeHeaderTab } from 'components/initiativeHeaderTab/initiativeHeaderTab'
import classes from './surveyResponses.module.css'
import { InitiativeQueryComponent } from 'components/initiativeQueryComponent/initiativeQueryComponent'
import { defaultSearchValueState } from 'constants/defaultStates'
import { InitiativePaginationComponent } from 'components/initiativePaginationComponent/initiativePaginationComponent'
import { Loader } from 'components/Loader/loader'
import moment from 'moment'
import SurveyAnalytics from './surveyAnalytics/surveyAnalytics'
import axios from 'axios'
import JSZip from "jszip";
import { saveAs } from 'file-saver'
import dummyLogo from '../../../../assets/image.png'
import UtilityContext from 'context/utilityContext'


const baseUrl = process.env.REACT_APP_BASE_URL
const SurveyResponses = () => {
    const { id } = useParams();

    const { totalImagesToDownload, setTotalImagesToDownload } = useContext(UtilityContext)

    const [responses, setResponses] = useState([]);
    const [responsesx, setResponsesx] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingx, setLoadingx] = useState(false);
    const [refetch, setRefetch] = useState("");
    const [modalType, setModalType] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);

    const [triggerClose, setTriggerClose] = useState("");
    const [user, setUser] = useState('')

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showAllDates, setShowAllDates] = useState(true)

    const [searchValues, setSearchValues] = useState(defaultSearchValueState)
    const { page } = searchValues

    const endDate = new Date(searchValues.endDate);  // Create a new date object based on the current date

    endDate.setDate(endDate.getDate() + 1);

    const currentUser = useSelector(({ userData }) => userData.currentUser);

    const limit = 500

    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();


    const handleSelectChange = (selectedOption) => {
        setUser(selectedOption?.value);
    };

    const [inputPage, setInputPage] = useState(page)

    const queryParams = [
        {
            key: 'survey',
            value: id
        },
        {
            key: 'page',
            value: page
        },
        {
            key: 'limit',
            value: limit
        },
        {
            key: 'search',
            value: searchValues.searchQuery
        },
        {
            key: 'createdAt[gte]}',
            value: searchValues.startDate
        },
        {
            key: 'createdAt[lt]',
            value: searchValues?.endDate ? endDate.toISOString().split('T')[0] : ""
        },
    ]

    const queryParamsx = [
        {
            key: 'surveyId',
            value: id
        },
        {
            key: 'user',
            value: user
        },
        {
            key: 'createdAt[gte]}',
            value: searchValues.startDate
        },
        {
            key: 'createdAt[lt]',
            value: searchValues?.endDate ? endDate.toISOString().split('T')[0] : ""
        },
    ]


    const getResponses = async () => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(buildResourceURL('/survey-responses', queryParams))
            setResponses(data?.data)
            setTotalImagesToDownload(data?.totalCount)
            if (responsesx?.length < 1) {
                setResponsesx(data?.data)
            }
        } catch (error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getResponses()
    }, [searchValues])

    const handleDateChange = (event) => {
        const newDate = new Date(event.target.value);
        setSelectedDate(newDate);
        setShowAllDates(false)
    };

    const handleModal = (type, user) => {
        setModalType(type)
        setSelectedUser(user)
        onOpen()
    }

    const deleteSurveyResponse = async () => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.delete(`survey-responses/${selectedUser?.id}`)
            toast({
                description: data?.message,
                status: "success",
                position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch (error) {
            if (error?.response?.data?.message) {
                toast({
                    title: "An Error Occurred",
                    description: error?.response?.data?.message,
                    status: "error",
                    position: "top"
                });
            }
        } finally {
            setLoadingx(false)
        }
    }


    const allUsers = responsesx?.map(i => i?.user?._id)
    const uniqueList = [...new Set(allUsers)]

    const userOptions = uniqueList?.map(i => ({
        label: `${responsesx?.find(v => v?.user?._id === i)?.user?.firstName} ${responsesx?.find(v => v?.user?._id === i)?.user?.lastName}`,
        value: i
    }))





    const assignor = {
        "Table": <TableView searchValues={searchValues} setSearchValues={setSearchValues} inputPage={inputPage} setInputPage={setInputPage}
            responses={responses} userOptions={userOptions} user={user} handleSelectChange={handleSelectChange} totalImagesToDownload={totalImagesToDownload} setUser={setUser} queryParamsx={queryParamsx} showAllDates={showAllDates} setShowAllDates={setShowAllDates} handleDateChange={handleDateChange} selectedDate={selectedDate} loading={loading} triggerClose={triggerClose} handleModal={handleModal} currentUser={currentUser} isOpen={isOpen} onClose={onClose} modalType={modalType} deleteSurveyResponse={deleteSurveyResponse} page={page} selectedUser={selectedUser} loadingx={loadingx} limit={limit}
        />,
        "Map": <MapWithMarkers responses={responses} />,
        "Analytics": <SurveyAnalytics />

    }


    const [selected, setSelected] = useState(Object.keys(assignor)[0])

    return (
        // <Box  overflowX={"scroll !important"} width="100% !important">
        // {loading ?
        // <SpinLoader />
        // :
        <div className={classes.container}>

            <div className={classes.backAndNameContainer}>
                <GoBack />
                <p className={classes.surveyName}>{responses[0]?.survey?.name}</p>
                <InitiativeHeaderTab list={Object.keys(assignor)} selected={selected} setSelected={setSelected} />
                {/* <Flex mb="2rem" alignItems={"center"} gap=".7rem">
                    <Box height={"45px"} width={"45px"} position={"relative"} backgroundColor={"lightgray"} backgroundImage={""} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}>
                        <MdVerified color="#1671D9" style={{ position: "absolute", zIndex: "10", bottom: "0", right: "-10%", transform: "scale(1.2)" }} />
                    </Box>
                    <Box>
                        <Text mb=".1rem" color={"#101828"} fontSize={"1.1rem"} fontWeight={"500"} textTransform={"capitalize"}>{responses[0]?.survey?.name}</Text>
                        <Text color={"#475467"} fontSize={".8rem"} fontWeight={"500"}>{responses[0]?.survey?.description}</Text>
                    </Box>
                </Flex> */}
            </div>


            {/* 
            <div className={classes.sticky}>
                <div className={classes.titleAndInstructionContainer}>
                    <p className={classes.title}>Total Surveys Found: {totalImagesToDownload}</p>
                    <p className={classes.instruction}>Click on each row to see more details</p>
                </div>

                <p className={classes.showing}>
                    Showing: <span>{responses.length || 1} of {totalImagesToDownload}</span>
                </p>

                <InitiativeQueryComponent
                    data={null}
                    searchValues={searchValues}
                    setSearchValues={setSearchValues}
                    filterList1={null}
                    entity='survey-responses' queryParamsx={queryParamsx}
                    placeholder={'Search by creator'}
                    smallInput={true}
                />
            </div> */}

            {
                loading ?

                    <div className={classes.loader_con}>
                        <Loader />
                    </div>
                    :

                    <>


                        <div>
                            {assignor[selected]}
                        </div>


                    </>
            }


        </div>

        // }
        // </Box>
    )
}

export default SurveyResponses;


function TableView({ responses, userOptions, user, handleSelectChange, totalImagesToDownload, setUser, queryParamsx, showAllDates, setShowAllDates, handleDateChange, selectedDate, loading, triggerClose, handleModal, currentUser, isOpen, onClose, modalType, deleteSurveyResponse, page, selectedUser, loadingx, setPage, limit, searchValues, setSearchValues, setInputPage, inputPage }) {
    const { id } = useParams();
    const { downloading, setDownloading, downloadCount, setDownloadCount, fetchAddress, setTotalImagesToDownload, cancelDownload, abortControllerRef } = useContext(UtilityContext);


    const downloadImages = async () => {
        setDownloading(true);
        setDownloadCount(0);

        abortControllerRef.current = new AbortController();
        const { signal } = abortControllerRef.current;

        try {
            const config = {
                headers: { authorization: `Bearer ${localStorage.getItem("PRTK")}` },
                signal, // Attach signal to request
            };

            const res = await axios.get(`${baseUrl}/survey-responses/download/images?survey=${id}&startDate=${searchValues.startDate}&endDate=${searchValues.endDate}`, config);
            const { data } = res.data;

            if (!data || data.length === 0) {
                console.warn("No data available for download");
                setDownloading(false);
                return;
            }

            const zip = new JSZip();
            let totalImages = 0;

            data.forEach(item => totalImages += item.responses.length);
            setTotalImagesToDownload(totalImages);

            const processImage = async (response, index) => {
                if (signal.aborted) return; // Stop processing if canceled

                if (!response?.image || !response?.longitude || !response?.latitude || !response?.question?.title || !response?.takenBy) {
                    console.warn("Skipping invalid response:", response);
                    return;
                }

                const { image, longitude, latitude, question, takenBy } = response;
                const address = await fetchAddress(latitude, longitude);

                const img = new Image();
                img.crossOrigin = "Anonymous";
                img.src = image;

                return new Promise((resolve) => {
                    img.onload = () => {
                        if (signal.aborted) return resolve(); // Stop if canceled

                        const canvas = document.createElement("canvas");
                        const context = canvas.getContext("2d");

                        canvas.width = img.width;
                        canvas.height = img.height;
                        context.drawImage(img, 0, 0);

                        // Draw "PROMORAMA" and "SURVEY" with transparency
                        context.save(); // Save the canvas state
                        context.globalAlpha = 0.2;
                        context.fillStyle = "black";
                        context.font = `bold ${canvas.width * 0.1}px Arial`;
                        context.textAlign = "center";
                        context.fillText("PROMORAMA", canvas.width / 2, canvas.height / 2);

                        context.font = `bold ${canvas.width * 0.05}px Arial`;
                        context.fillText("APP", canvas.width / 2, canvas.height / 2 + (canvas.width * 0.06));
                        context.restore(); // Restore canvas state to reset globalAlpha

                        // Draw bottom text without transparency
                        context.fillStyle = "rgba(0, 0, 0, 0.7)";
                        context.fillRect(0, img.height - 100, img.width, 100);

                        context.fillStyle = "#FFFFFF";
                        context.font = "bold 18px Arial";
                        context.fillText(`📍 ${address}`, 10, img.height - 80);
                        context.fillText(`🌍 Lat: ${latitude}, Long: ${longitude}`, 10, img.height - 60);
                        context.fillText(`📝 Title: ${question.title}`, 10, img.height - 40);
                        context.fillText(`📸 Taken By: ${takenBy}`, 10, img.height - 20);

                        const canvasDataUrl = canvas.toDataURL("image/png");
                        zip.file(`Image_${index + 1}.png`, canvasDataUrl.split(",")[1], { base64: true });

                        setDownloadCount((prev) => prev + 1);
                        resolve();
                    };
                });
            };

            for (let i = 0; i < data.length; i += 3) {
                if (signal.aborted) break; // Stop batch processing if canceled
                await Promise.all(data.slice(i, i + 5).flatMap(item => item.responses.map(processImage)));
            }

            if (!signal.aborted) {
                const zipBlob = await zip.generateAsync({ type: "blob" });
                saveAs(zipBlob, "Images_with_Text.zip");
            }

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log("Download canceled");
            } else {
                console.error("Error downloading images:", error);
            }
        } finally {
            setDownloading(false);
        }
    };



    return (
        <div>
            <div className={classes.sticky}>



                <p className={classes.showing}>
                    Total survey responses found: <span> {totalImagesToDownload}</span>
                </p>

                <InitiativeQueryComponent
                    data={null}
                    searchValues={searchValues}
                    setSearchValues={setSearchValues}
                    filterList1={null}
                    entity='survey-responses' queryParamsx={queryParamsx}
                    placeholder={'Search by creator'}
                    smallInput={true}
                />

                <button onClick={downloadImages} disabled={downloading} className={classes.downloadBtn}>
                    {downloading ? "Downloading..." : "Download Images"}
                </button>
            </div>



            <Box p="0rem" bg="#FFF" mt="1rem" overflowY={"scroll"} borderRadius={"12px"}>

                <Table mt="1.2rem" borderRadius={"12px"} overflowX={"scroll !important"}>
                    <Thead bg="#F9FAFB" border="1px solid #EAECF0">
                        <Tr>
                            {responses[0]?.survey?.questions?.slice(0, 6)?.map(({ fieldName }) => {
                                return (
                                    <Tooltip key={fieldName} label={fieldName} hasArrow>
                                        <Th textTransform={"capitalize !important"} color={"#475467"}>{fieldName}</Th>
                                    </Tooltip>

                                )
                            })}
                            <Th textTransform={"capitalize !important"}>Creator</Th>
                            <Th textTransform={"capitalize !important"}>Date</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>

                    {loading ?
                        <LineSkeleton width="100%" num={10} />
                        :
                        responses?.length < 1 ?
                            <EmptyState mt="1.5rem" text="There are no submissions yet" />
                            :
                            <Tbody>

                                {responses?.map((({ entries, _id: id, survey, user, createdAt }, index) => {
                                    const data = { entries }
                                    return (
                                        <Tr key={id}>

                                            {survey?.questions?.slice(0, 6)?.map(({ _id: id }, index) => (
                                                <Tooltip key={id} label={entries[index]?.answer} hasArrow>
                                                    <Td>
                                                        <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{truncateText(entries?.find(x => x?.questionIndex === index)?.answer, 25) || '--'}</Text>
                                                    </Td>
                                                </Tooltip>
                                            ))}

                                            <Td>
                                                <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{`${user?.firstName} ${user?.lastName}`}</Text>
                                            </Td>

                                            <Td>
                                                <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{moment(createdAt).calendar()}</Text>
                                            </Td>

                                            <Td>
                                                <Menu width={"200px !important"}>
                                                    <MenuButton>
                                                        <BiDotsVerticalRounded style={{ cursor: "pointer" }} />
                                                    </MenuButton>
                                                    <MenuList>
                                                        <Divider my=".2rem" />
                                                        <CustomDrawer
                                                            position={"right"}
                                                            title={'View Submission'}
                                                            triggerClose={triggerClose}
                                                            toggleElement={
                                                                <MenuItem fontWeight={"500"} fontSize={".85rem"}>View Submission</MenuItem>
                                                            }
                                                            content={
                                                                <ViewResponse data={responses[index]} />
                                                            }
                                                        />
                                                        {(currentUser?.role === 'Super Admin') &&
                                                            <>
                                                                <Divider my=".2rem" />
                                                                <MenuItem onClick={() => handleModal('delete', { name: entries[0]?.answer, id })} color={"crimson"} fontWeight={"500"} fontSize={".85rem"}>Delete Submission</MenuItem>
                                                            </>
                                                        }
                                                    </MenuList>
                                                </Menu>
                                            </Td>
                                        </Tr>
                                    )
                                }))
                                }
                            </Tbody>}
                </Table>

                <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{`${modalType} Submission`}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {modalType === 'delete' &&
                                <>
                                    <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm deletion of {selectedUser?.name}?</Text>
                                    <Flex justifyContent={"center"} my="1.3rem">
                                        <PrimaryButton onClick={deleteSurveyResponse} text="Delete" width="150px" bg="crimson !important" padding="1rem !important" loading={loadingx} />
                                    </Flex>
                                </>
                            }
                            <Text pb="1rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Cancel</Text>
                        </ModalBody>
                    </ModalContent>
                </Modal>

            </Box>

            <InitiativePaginationComponent
                page={page} totalCount={totalImagesToDownload} setSearchValues={setSearchValues} setInputPage={setInputPage} inputPage={inputPage} limit={limit}
            />

        </div>
    )
}