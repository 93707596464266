import {
  Box,
  Checkbox,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BiCalendarAlt, BiDotsVerticalRounded } from "react-icons/bi";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import CustomDrawer from "../../../../components/customDrawer/customDrawer";
import axiosInstance from "../../../../services/api";
import SecondaryButton from "../../../../components/secondaryButton/secondarybutton";
import EmptyState from "../../../../components/emptyState/emptyState";
import PrimaryButton from "../../../../components/primaryButton/primarybutton";
import LineSkeleton from "../../../../components/lineSkeleton";
import CreateStore from "../createStore/createStore";
import ViewStore from "../viewStore/viewStore";
import CustomInput from "../../../../components/customInput/customInput";
import CustomSelect from "../../../../components/customSelect/customSelect";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  buildResourceURL,
  buildResourceURL2,
  truncateText,
} from "../../../../utils/helper";
import ListMap from "../../../../components/mapView/mapViewList";
import { useSelector } from "react-redux";
import CustomPagination from "../../../../components/customPagination/customPagination";
import EditStore from "../editStore/editStore";
import { useNavigate } from "react-router-dom";
import LinkStore from "../linkStore/linkStore";
import ServerExport from "../../../../components/serverExport/serverExport";
import TeamAssign from "./teamAssign/teamAssign";
import countriesData from "../../../../utils/countries.json";

const statusOptions = [
  {
    title: "All",
    value: "all",
  },
  {
    title: "Pending",
    value: false,
  },
  {
    title: "Approved",
    value: true,
  },
];

const StoresTable = ({ clientId }) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const creator = params.get("creator");

  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingc, setLoadingc] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [triggerClose, setTriggerClose] = useState("");
  const [modalType, setModalType] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [refetch, setRefetch] = useState("");
  const [binView, setBinView] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("all");
  const [team, setTeam] = useState("");
  const [total, setTotal] = useState("");
  const [region, setRegion] = useState("");

  const [selectedStores, setSelectedStores] = useState([]);
  const [isSelecting, setIsSelecting] = useState(false);

  const [country, setCountry] = useState("");

  const [category, setCategory] = useState("");

  const currentUser = useSelector(({ userData }) => userData.currentUser);
  const subid = currentUser?.role?.includes("Subuser")
    ? currentUser?.mainUser
    : "";

  const storeUrl =
    currentUser?.role === "Agency" ? "agencies/stores" : "stores";

  const regionsData = currentUser?.statesFilterGroups;
  const regionsDataOpts = regionsData?.map((i) => ({
    title: i?.name,
    value: i?.name,
  }));

  const [defaultView, setDefaultView] = useState("list");

  const [searchVal, setSearchVal] = useState("");
  const [categories, setCategories] = useState([]);
  const [type, setType] = useState("");
  const [loadingcx, setLoadingcx] = useState(true);

  const [showDateFilter, setShowDateFilter] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const limit = 10;

  const getCategories = async () => {
    setLoadingcx(true);
    try {
      const { data } = await axiosInstance.get(`store-categories`);
      setCategories(data?.data);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoadingcx(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const categoriesx = !loadingcx
    ? categories?.map((i) => ({
        title: i?.name,
        value: i?.name,
        listValue: i?.types?.map((v) => ({
          title: v?.name,
          value: v?.name,
          listValue: v?.levels?.map((k) => ({
            title: k,
            value: k,
          })),
        })),
      }))
    : [];

  // const arr1x = categories?.map(i => i?.types)
  // const arr1xy = arr1x?.map(i => i?.map(v => v?.name)).flat()
  // const typeOptions = !loadingcx ?
  // arr1xy?.map(i => ({
  //     title: i,
  //     value: i
  // }))
  // :
  // []

  const queryParams = [
    {
      key: "isDeleted",
      value: binView === false ? "" : binView,
    },
    {
      key: "createdAt[gte]",
      value: startDate,
    },
    {
      key: "createdAt[lt]",
      value: endDate,
    },
    {
      key: "isApproved",
      value: status === "all" ? "" : status,
    },
    {
      key: "team",
      value: team,
    },
    {
      key: "clients[in]",
      value:
        currentUser?.role === "Client"
          ? currentUser?._id
          : currentUser?.role?.includes("Subuser")
          ? subid
          : clientId,
    },
    {
      key: "search",
      value: searchVal,
    },
    {
      key: "user",
      value: creator,
    },
    {
      key: "page",
      value: page,
    },
    {
      key: "limit",
      value: defaultView === "map" ? 20000 : limit,
    },
    {
      key: "type",
      value: type,
    },
    {
      key: "country",
      value: country,
    },
    {
      key: "category",
      value: category,
    },
    ...(regionsData
      ?.find((i) => i?.name === region)
      ?.states?.map((k) => ({
        key: "state[in]",
        value: k,
      })) || []),
  ];

  const queryParamsx = [
    {
      key: "isDeleted",
      value: binView === false ? "" : binView,
    },
    {
      key: "createdAt[gte]",
      value: startDate,
    },
    {
      key: "createdAt[lt]",
      value: endDate,
    },
    {
      key: "isApproved",
      value: status === "all" ? "" : status,
    },
    {
      key: "team",
      value: team,
    },
    {
      key: "clients[in]",
      value:
        currentUser?.role === "Client"
          ? currentUser?._id
          : currentUser?.role?.includes("Subuser")
          ? subid
          : clientId,
    },
    {
      key: "clientId",
      value: clientId,
    },
    {
      key: "search",
      value: searchVal,
    },
    {
      key: "user",
      value: creator,
    },
    {
      key: "type",
      value: type,
    },
    {
      key: "category",
      value: category,
    },
    {
      key: "country",
      value: country,
    },
  ];

  const getStores = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        buildResourceURL(storeUrl, queryParams)
      );
      setStores(data?.data);
      setTotal(data?.totalCount);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStores();
  }, [
    triggerClose,
    refetch,
    status,
    startDate,
    searchVal,
    page,
    team,
    creator,
    type,
    region,
    category,
    defaultView,
    country,
  ]);

  const handleModal = (type, user) => {
    setModalType(type);
    setSelectedUser(user);
    onOpen();
  };

  const approveEmployee = async () => {
    setLoadingx(true);
    const datax = {
        storeIDs: selectedStores?.length > 0 ? selectedStores : [selectedUser?.id]
    }
    try {
      const { data } = await axiosInstance.patch(
        `${storeUrl}/approve`, datax
        // `${storeUrl}/${selectedUser?.id}/approve`
      );
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setRefetch(Math.random());
      setIsSelecting(false)
      setSelectedStores([])
      
      onClose();
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingx(false);
    }
  };

  const suspendEmployee = async () => {
    setLoadingx(true);
    try {
      const { data } = await axiosInstance.delete(
        `${storeUrl}/${selectedUser?.id}/soft`
      );
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setRefetch(Math.random());
      onClose();
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingx(false);
    }
  };

  const restoreEmployee = async () => {
    setLoadingx(true);
    try {
      const { data } = await axiosInstance.patch(
        `${storeUrl}/${selectedUser?.id}/restore`
      );
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setRefetch(Math.random());
      onClose();
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingx(false);
    }
  };

  const deleteEmployee = async () => {
    setLoadingx(true);
    try {
      const { data } = await axiosInstance.delete(
        `${storeUrl}?storeIDs=${selectedStores?.join(',') || selectedUser?.id}`
        // `${storeUrl}/${selectedUser?.id}`
      );
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setRefetch(Math.random());
      setIsSelecting(false)
      setSelectedStores([])
      onClose();
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingx(false);
    }
  };

  const removeEmployee = async () => {
    setLoadingx(true);
    const datax = {
      clientId: clientId || currentUser?._id,
    };
    try {
      const { data } = await axiosInstance.patch(
        `${storeUrl}/${selectedUser?.id}/remove-client`,
        datax
      );
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setRefetch(Math.random());
      onClose();
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingx(false);
    }
  };

  const removeEmployeex = async () => {
    setLoadingx(true);
    const datax = {
      storeId: selectedUser,
    };
    try {
      const { data } = await axiosInstance.delete(
        `${storeUrl}/${selectedUser?.id}`,
        datax
      );
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setRefetch(Math.random());
      onClose();
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingx(false);
    }
  };

  const handleViewBin = () => {
    setBinView(!binView);
    setRefetch(Math.random());
  };

  const handleSelect = (ranges) => {
    const date1 = new Date(ranges?.selection?.startDate);
    const date2 = new Date(ranges?.selection?.endDate);

    const year1 = date1.getFullYear();
    const month1 = String(date1.getMonth() + 1).padStart(2, "0");
    const day1 = String(date1.getDate()).padStart(2, "0");

    const year2 = date2.getFullYear();
    const month2 = String(date2.getMonth() + 1).padStart(2, "0");
    const day2 = String(date2.getDate()).padStart(2, "0");

    const formattedDate1 = `${year1}-${month1}-${day1}`;
    const formattedDate2 = `${year2}-${month2}-${day2}`;

    setStartDate(formattedDate1);
    setEndDate(formattedDate2);

    setRefetch(Math.random());
  };

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  const clearDates = () => {
    setStartDate("");
    setEndDate("");
    setShowDateFilter(false);
  };

  const coordinates = stores?.map((i) => ({
    name: i?.name,
    coordinates: [i?.location?.coordinates[0], i?.location?.coordinates[1]],
  }));

  const teamOptions = [{ title: "All Teams", value: "" }];

  for (let i = 0; i < 100; i++) {
    const newObj = {
      title: `Team ${i + 1}`,
      value: i + 1,
    };
    teamOptions.push(newObj);
  }

  const arrx =
    categoriesx?.filter((i) => i.value === category)[0]?.listValue || [];
  const arrxy = [{ title: "All Types", value: "" }, ...arrx];

  const countries = countriesData?.map((x) => ({
    title: x?.name,
    value: x?.name?.toLowerCase(),
  }));

  const handleSelectx = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add item to the array if it doesn't already exist
      if (!selectedStores.includes(value)) {
        setSelectedStores([...selectedStores, value]);
      }
    } else {
      // Remove item from the array if it exists
      const filteredItems = selectedStores.filter((item) => item !== value);
      setSelectedStores(filteredItems);
    }
  };

  const selectAll = () => {
    if (selectedStores?.length === stores?.length) {
      setSelectedStores([]);
    } else {
      setSelectedStores(stores?.map((i) => i?._id));
    }
  };

  const handleIsSelecting = () => {
    if (isSelecting === true) {
      setSelectedStores([]);
      setIsSelecting(false);
    } else {
      setIsSelecting(true);
    }
  };

  return (
    <>
      <Flex mb="1rem" justify={"flex-end"} gap=".7rem" alignItems={"center"}>
        {currentUser?.role === "Client" && (
          <CustomSelect
            selection={region}
            setSelection={setRegion}
            options={[{ title: "All Regions", value: "" }, ...regionsDataOpts]}
            height="37px"
            fontSize=".8rem"
            width="150px"
            mt="0"
            borderRadius={"12px"}
            cursor="pointer"
          />
        )}
        <Flex
          p=".7rem"
          alignItems={"center"}
          gap=".5rem"
          height="40px !important"
          borderRadius={"12px"}
          position={"relative"}
          cursor={"pointer"}
        >
          <Text
            onClick={() => setShowDateFilter(!showDateFilter)}
            whiteSpace={"nowrap"}
          >
            {showDateFilter ? "Close Filter" : "Filter Date"}
          </Text>
          <BiCalendarAlt
            onClick={() => setShowDateFilter(!showDateFilter)}
            style={{ opacity: showDateFilter ? "0" : "1" }}
          />
          {showDateFilter && (
            <Box
              position={"absolute"}
              bg="gray"
              top="120%"
              left="-275%"
              p=".02rem"
              zIndex={"20"}
            >
              <Flex
                py=".7rem"
                alignItems={"center"}
                px=".8rem"
                justifyContent={"space-between"}
              >
                <Text
                  whiteSpace={"nowrap"}
                  fontWeight={"500"}
                  fontSize={".95rem"}
                  color={"#FFF"}
                ></Text>
                <Text
                  onClick={clearDates}
                  whiteSpace={"nowrap"}
                  fontWeight={"500"}
                  fontSize={".95rem"}
                  color={"#FFF"}
                >
                  Show all
                </Text>
              </Flex>

              {
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={handleSelect}
                  showMonthAndYearPickers={false}
                />
              }
            </Box>
          )}
        </Flex>
        {creator && (
          <Text
            fontSize={".85rem"}
            color="#7F56D9"
            onClick={() => navigate("/stores")}
            cursor={"pointer"}
            fontWeight={"500"}
            textDecoration={"underline"}
          >
            Full Outlets List
          </Text>
        )}
        <Flex
          transition={"250ms ease"}
          borderRadius={"6px"}
          alignItems={"center"}
          bg="#F2F4F7"
          gap=".5rem"
          padding={".3rem .4rem"}
        >
          <Text
            onClick={() => setDefaultView("list")}
            cursor={"pointer"}
            transition={"250ms ease"}
            borderRadius={"4px"}
            whiteSpace={"nowrap"}
            fontSize={".85rem"}
            padding={".3rem .5rem"}
            fontWeight={defaultView === "list" ? "500" : ""}
            color={defaultView === "list" ? "#6941C6" : ""}
            bg={defaultView === "list" ? "#FFF" : ""}
          >
            List View
          </Text>
          <Text
            onClick={() => setDefaultView("map")}
            cursor={"pointer"}
            transition={"250ms ease"}
            borderRadius={"4px"}
            whiteSpace={"nowrap"}
            fontSize={".85rem"}
            padding={".3rem .5rem"}
            fontWeight={defaultView === "map" ? "500" : ""}
            color={defaultView === "map" ? "#6941C6" : ""}
            bg={defaultView === "map" ? "#FFF" : ""}
          >
            Map View
          </Text>
        </Flex>
        {currentUser?.role === "Super Admin" && (
          <Menu width={"200px !important"}>
            <MenuButton>
              <BiDotsVerticalRounded style={{ cursor: "pointer" }} />
            </MenuButton>
            <MenuList>
              <Divider my=".2rem" />
              <MenuItem
                onClick={handleViewBin}
                fontWeight={"500"}
                fontSize={".85rem"}
              >
                {binView ? "Back to full list" : "View Bin"}
              </MenuItem>
              <Divider my=".2rem" />
            </MenuList>
          </Menu>
        )}
      </Flex>
      <Flex alignItems={"center"} gap=".8rem" justifyContent={"space-between"}>
        <Flex gap="1.5rem" alignItems={"center"}>
          <Text
            whiteSpace={"nowrap"}
            fontWeight={"500"}
            fontSize={"1.15rem"}
            color={"#27272A"}
          >
            Outlets{" "}
            <span
              style={{
                padding: ".1rem .3rem",
                borderRadius: "50%",
                backgroundColor: "#F9FAFB",
                border: "1px solid #EAECF0",
                fontSize: "12px",
                fontWeight: "500",
                color: "#344054",
              }}
            >
              {total}
            </span>
          </Text>
          {/* <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>Stores <span style={{padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054"}}>{creator ? stores?.length : (currentUser?.role === "Client" ? stores?.length : total)}</span></Text> */}
          <CustomSelect
            required
            options={statusOptions}
            onChange={(e) => setStatus(e.target.value)}
            height="40px !important"
            mt="0"
            borderRadius={"12px"}
            width="120px"
            cursor="pointer"
          />
          <CustomSelect
            required
            options={[{ title: "All Countries", value: "" }, ...countries]}
            onChange={(e) => setCountry(e.target.value)}
            height="40px !important"
            mt="0"
            borderRadius={"12px"}
            width="120px"
            cursor="pointer"
          />
          <CustomInput
            type={"text"}
            required
            value={searchVal}
            placeholder={"Search outlets.."}
            onChange={(e) => setSearchVal(e.target.value)}
            mt="0"
            sType="search"
            py="1.19rem"
            width="250px"
          />
          <CustomSelect
            required
            options={teamOptions}
            onChange={(e) => setTeam(e.target.value)}
            height="40px !important"
            mt="0"
            borderRadius={"12px"}
            width="120px"
            cursor="pointer"
          />
          {/* <CustomSelect
                required
                options={[{title: "All Types", value: ""}, ...typeOptions]}
                onChange={e => setType(e.target.value)}
                height="40px !important"
                mt="0"
                borderRadius={"12px"}
                width="120px"
                cursor="pointer"
                /> */}
          <CustomSelect
            options={categoriesx}
            placeholder={"All category"}
            onChange={(e) => setCategory(e.target.value)}
            height="40px !important"
            mt="0"
            borderRadius={"12px"}
            width="100px"
            cursor="pointer"
          />
          <CustomSelect
            options={arrxy}
            placeholder={""}
            onChange={(e) => setType(e.target.value)}
            height="40px !important"
            mt="0"
            borderRadius={"12px"}
            width="100px"
            cursor="pointer"
          />
        </Flex>

        <Flex gap="1rem" alignItems={"center"}>
          <ServerExport
            entity={"stores"}
            queries={buildResourceURL2("", queryParamsx)}
          />

          {(clientId || currentUser?.role === "Client") && (
            <CustomDrawer
              position={"right"}
              title={"Link Outlet"}
              triggerClose={triggerClose}
              setTriggerClose={setTriggerClose}
              toggleElement={
                <SecondaryButton
                  type="submit"
                  text={"Link Outlet"}
                  icon="add"
                  loading={false}
                />
              }
              content={
                <LinkStore
                  loading={loading}
                  stores={!loading && stores}
                  setTriggerClose={setTriggerClose}
                  clientId={clientId}
                />
              }
            />
          )}
          <CustomDrawer
            position={"right"}
            title={"Add Outlet"}
            triggerClose={triggerClose}
            setTriggerClose={setTriggerClose}
            toggleElement={
              <SecondaryButton
                type="submit"
                text={"Add Outlet"}
                icon="add"
                loading={false}
              />
            }
            content={
              <CreateStore
                loading={loadingc}
                setTriggerClose={setTriggerClose}
                setLoading={setLoadingc}
              />
            }
          />
        </Flex>
      </Flex>
      
      {currentUser?.role === "Super Admin" &&
      <Flex my="1rem" justifyContent={"flex-end"} gap={".7rem"}>
        {isSelecting == true && 
        <>
        <PrimaryButton py=".5rem" width="150px" text="Approve Stores" loading={false} onClick={() => handleModal("approve", { name: '', id: '' })} />
        <PrimaryButton py=".5rem" width="150px" text="Delete Stores" loading={false} onClick={() => handleModal("delete", { name: '', id: '' })} />
        </>}
        <SecondaryButton
              onClick={handleIsSelecting}
              text={
                isSelecting === true ? "Cancel Selection" : "Select Stores"
              }
              loading={false}
            />
      </Flex>}

      <Box
        p="0rem 0rem 0rem .5rem"
        bg="#FFF"
        mt="1rem"
        height={"84vh"}
        overflowY={"scroll"}
        borderRadius={"12px"}
      >
        {/* <Flex my=".7rem" justifyContent={"flex-end"}>
            <Flex alignItems={"center"} bg="#F2F4F7" gap=".5rem" padding={".3rem .4rem"}>
                <Text fontSize={".85rem"} padding={".3rem .5rem"} fontWeight={defaultView === "list" ? "500" : ""} color={defaultView === "list" ? "#6941C6" : ""} bg={defaultView === "list" ? "#FFF" : ""}>List View</Text>
                <Text fontSize={".85rem"} padding={".3rem .5rem"} fontWeight={defaultView === "map" ? "500" : ""} color={defaultView === "map" ? "#6941C6" : ""} bg={defaultView === "map" ? "#FFF" : ""}>Map View</Text>
            </Flex>
        </Flex> */}
        {defaultView === "list" ? (
          <Table mt="1.2rem" borderRadius={"12px"} overflow={"hidden"}>
            <Thead bg="#F9FAFB" border="1px solid #EAECF0">
              <Tr>
                {isSelecting === true && (
                  <th>
                    <Checkbox
                      onChange={selectAll}
                      isChecked={selectedStores?.length === stores?.length}
                      colorScheme="green"
                    />
                  </th>
                )}
                <Th textTransform={"capitalize !important"} color={"#475467"}>
                  Name
                </Th>
                {/* <Th textTransform={"capitalize !important"} color={"#475467"}>Category</Th> */}
                <Th textTransform={"capitalize !important"} color={"#475467"}>
                  Category/Type/Level
                </Th>
                <Th textTransform={"capitalize !important"} color={"#475467"}>
                  Brand/Additional Info
                </Th>
                {/* <Th textTransform={"capitalize !important"} color={"#475467"}>Additional Info</Th> */}
                <Th textTransform={"capitalize !important"} color={"#475467"}>
                  Owner
                </Th>
                <Th textTransform={"capitalize !important"} color={"#475467"}>
                  Created
                </Th>
                <Th textTransform={"capitalize !important"} color={"#475467"}>
                  Creator
                </Th>
                <Th textTransform={"capitalize !important"} color={"#475467"}>
                  Team
                </Th>
                <Th textTransform={"capitalize !important"} color={"#475467"}>
                  Status
                </Th>
                <Th></Th>
              </Tr>
            </Thead>

            {loading ? (
              <LineSkeleton width="100%" num={10} />
            ) : stores?.length < 1 ? (
              <EmptyState mt="1.5rem" text="There are no stores yet" />
            ) : (
              <Tbody>
                {stores?.map(
                  ({
                    _id: id,
                    name,
                    ownerName,
                    ownerPhoneNumber,
                    phoneNumber,
                    team,
                    type,
                    isApproved,
                    isDeleted,
                    createdAt,
                    area,
                    state,
                    level,
                    category,
                    imageURL,
                    location,
                    comment,
                    user,
                    userRole,
                    address,
                    ownerEmail,
                    productSource,
                    purchaseWillingness,
                    buyingPreferences,
                    brand,
                    additionalInfo,
                    streetNumber,
                    streetName,
                  }) => {
                    const data = {
                      id,
                      name,
                      ownerName,
                      ownerPhoneNumber,
                      type,
                      isApproved,
                      isDeleted,
                      createdAt,
                      area,
                      state,
                      level,
                      category,
                      imageURL,
                      location,
                      comment,
                      user,
                      userRole,
                      address,
                      team,
                      phoneNumber,
                      ownerEmail,
                      productSource,
                      purchaseWillingness,
                      buyingPreferences,
                      brand,
                      additionalInfo,
                      streetNumber,
                      streetName,
                    };
                    return (
                      <Tr key={id}>
                        {isSelecting === true && (
                          <td>
                            <Checkbox
                              onChange={(e) => handleSelectx(e)}
                              value={id}
                              isChecked={selectedStores?.includes(id)}
                              colorScheme="green"
                            />
                          </td>
                        )}
                        <Td>
                          <Flex alignItems={"center"} gap=".6rem">
                            {/* <Box height={"40px"} borderRadius={"4px"} width={"45px !important"} bg="lightgrey" backgroundImage={imageURL} backgroundSize={"cover"}  /> */}
                            <Box>
                              <Text
                                mb=".2rem"
                                color={"#101828"}
                                fontSize={".9rem"}
                                fontWeight={"500"}
                                textTransform={"capitalize"}
                              >
                                {name}
                              </Text>
                              <Text
                                color={"#101828"}
                                fontSize={".85rem"}
                                opacity={".6"}
                                fontWeight={"500"}
                                textTransform={"capitalize"}
                              >{`${area} ${state}`}</Text>
                              <Text
                                color={"#101828"}
                                fontSize={".85rem"}
                                opacity={".9"}
                                fontWeight={"500"}
                                textTransform={"capitalize"}
                              >
                                {phoneNumber}
                              </Text>
                            </Box>
                          </Flex>
                        </Td>
                        {/* <Td color={"#475467"} fontSize={".9rem"} textTransform={"capitalize"}>{state}</Td> */}
                        {/* <Td color={"#475467"} fontSize={".9rem"}>{category || "--"}</Td> */}
                        <Td color={"#475467"} fontSize={".9rem"}>
                          {category}/{type}/{level}
                        </Td>
                        <Td color={"#475467"} fontSize={".9rem"}>
                          <Box>
                            <Text
                              mb=".2rem"
                              color={"#101828"}
                              fontSize={".9rem"}
                              fontWeight={"500"}
                              textTransform={"capitalize"}
                            >
                              {brand || "--"}
                            </Text>
                            <Text
                              color={"#101828"}
                              fontSize={".85rem"}
                              opacity={".6"}
                              fontWeight={"500"}
                              textTransform={"capitalize"}
                            >
                              , {truncateText(additionalInfo, 20)}
                            </Text>
                          </Box>
                        </Td>
                        {/* <Td color={"#475467"} fontSize={".9rem"}>{additionalInfo ? `${additionalInfo?.slice(0,17)}..` : '--'}</Td> */}
                        <Td
                          color={"#475467"}
                          fontWeight={"500"}
                          fontSize={".85rem"}
                        >
                          <Box>
                            <Text
                              mb=".2rem"
                              color={"#101828"}
                              fontSize={".9rem"}
                              fontWeight={"500"}
                              textTransform={"capitalize"}
                            >
                              {ownerName}
                            </Text>
                            <Text
                              mb=".2rem"
                              color={"#101828"}
                              fontSize={".85rem"}
                              opacity={".6"}
                              fontWeight={"500"}
                              textTransform={"capitalize"}
                            >
                              {ownerPhoneNumber}
                            </Text>
                          </Box>
                        </Td>
                        <Td color={"#475467"} fontSize={".8rem"}>
                          {createdAt?.slice(0, 10)}
                        </Td>
                        <Td
                          fontWeight={"500"}
                          whiteSpace={"nowrap"}
                          fontSize={".85rem"}
                        >
                          {user &&
                            (userRole === "Client"
                              ? "🆑"
                              : userRole === "Promoter"
                              ? "🅿️"
                              : userRole === "Supervisor"
                              ? "🆚"
                              : "🅰️")}{" "}
                          {user
                            ? userRole === "Client"
                              ? user?.companyName
                              : user?.firstName +
                                " " +
                                user?.lastName?.slice(0, 1).toUpperCase()
                            : "--"}
                        </Td>
                        <Td color={"#475467"} fontSize={".9rem"}>
                          {team ? team : "--"}
                        </Td>
                        <Td>
                          <Text
                            textAlign={"center"}
                            fontSize={"12px"}
                            fontWeight={"500"}
                            p=".1rem"
                            borderRadius={"16px"}
                            border={
                              isApproved
                                ? "1px solid #ABEFC6"
                                : "1px solid lightgray"
                            }
                            color={isApproved ? "#067647" : "#475467"}
                            bg={isApproved ? "#ECFDF3" : "#F9FAFB"}
                          >
                            {isApproved ? "Verified" : "Unverified"}
                          </Text>
                        </Td>
                        <Td>
                          <Menu width={"200px !important"}>
                            <MenuButton>
                              <BiDotsVerticalRounded
                                style={{ cursor: "pointer" }}
                              />
                            </MenuButton>
                            <MenuList>
                              <Divider my=".2rem" />
                              <CustomDrawer
                                position={"right"}
                                title={"Outlet details"}
                                triggerClose={triggerClose}
                                toggleElement={
                                  <MenuItem
                                    fontWeight={"500"}
                                    fontSize={".85rem"}
                                  >
                                    View Outlet
                                  </MenuItem>
                                }
                                content={<ViewStore data={data} />}
                              />
                              <Divider my=".2rem" />
                              {/* {currentUser?.role !== "Client" &&
                                    <> */}
                              {!isApproved && (
                                <MenuItem
                                  onClick={() =>
                                    handleModal("approve", { name, id })
                                  }
                                  fontWeight={"500"}
                                  fontSize={".85rem"}
                                >
                                  Approve Outlet
                                </MenuItem>
                              )}

                              {!currentUser?.role?.includes("Subuser") && (
                                <>
                                  <Divider my=".2rem" />
                                  <CustomDrawer
                                    position={"right"}
                                    title={"Outlet details"}
                                    triggerClose={triggerClose}
                                    toggleElement={
                                      <MenuItem
                                        fontWeight={"500"}
                                        fontSize={".85rem"}
                                      >
                                        Create as initiative store
                                      </MenuItem>
                                    }
                                    content={
                                      <TeamAssign
                                        triggerClose={triggerClose}
                                        setTriggerClose={setTriggerClose}
                                        setRefetch={setRefetch}
                                        storeId={id}
                                        promoter={user}
                                      />
                                    }
                                  />
                                  <>
                                    <Divider my=".2rem" />
                                    <MenuItem
                                      onClick={() =>
                                        handleModal("remove", { name, id })
                                      }
                                      fontWeight={"500"}
                                      fontSize={".85rem"}
                                    >
                                      Remove Outlet
                                    </MenuItem>
                                  </>
                                </>
                              )}
                              <Divider my=".2rem" />
                              {currentUser?.role === "Super Admin" && (
                                <>
                                  {isDeleted ? (
                                    <MenuItem
                                      onClick={() =>
                                        handleModal("restore", { name, id })
                                      }
                                      fontWeight={"500"}
                                      fontSize={".85rem"}
                                    >
                                      Restore Outlet
                                    </MenuItem>
                                  ) : (
                                    <MenuItem
                                      onClick={() =>
                                        handleModal("suspend", { name, id })
                                      }
                                      fontWeight={"500"}
                                      fontSize={".85rem"}
                                    >
                                      Suspend Outlet
                                    </MenuItem>
                                  )}
                                  {isApproved === false && (
                                    <>
                                      <Divider my=".2rem" />
                                      <CustomDrawer
                                        position={"right"}
                                        title={"Outlet details"}
                                        triggerClose={triggerClose}
                                        toggleElement={
                                          <MenuItem
                                            fontWeight={"500"}
                                            fontSize={".85rem"}
                                          >
                                            Edit Outlet
                                          </MenuItem>
                                        }
                                        content={
                                          <EditStore
                                            storeData={data}
                                            setTriggerClose={setTriggerClose}
                                            setRefetch={setRefetch}
                                          />
                                        }
                                      />
                                    </>
                                  )}

                                  {isApproved === true &&
                                    currentUser?.role === "Super Admin" && (
                                      <>
                                        <Divider my=".2rem" />
                                        <CustomDrawer
                                          position={"right"}
                                          title={"Outlet details"}
                                          triggerClose={triggerClose}
                                          toggleElement={
                                            <MenuItem
                                              fontWeight={"500"}
                                              fontSize={".85rem"}
                                            >
                                              Edit Outlet
                                            </MenuItem>
                                          }
                                          content={
                                            <EditStore
                                              storeData={data}
                                              setTriggerClose={setTriggerClose}
                                              setRefetch={setRefetch}
                                            />
                                          }
                                        />
                                      </>
                                    )}
                                  <Divider my=".2rem" />
                                  <MenuItem
                                    onClick={() =>
                                      handleModal("delete", { name, id })
                                    }
                                    color={"crimson"}
                                    fontWeight={"500"}
                                    fontSize={".85rem"}
                                  >
                                    Delete Outlet
                                  </MenuItem>
                                </>
                              )}
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    );
                  }
                )}
              </Tbody>
            )}
          </Table>
        ) : (
          <Box mt="1.2rem">
            <ListMap coordinates={coordinates} height={"70vh"} />
          </Box>
        )}

        <Modal
          isCentered
          motionPreset="slideInBottom"
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              fontSize={"1rem"}
              textTransform={"capitalize"}
            >{`${modalType} Outlet`}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {modalType === "approve" && (
                <>
                  <Text
                    textAlign={"center"}
                    color={"#475467"}
                    fontWeight={"500"}
                  >
                    Confirm approval of {selectedUser?.name || `${selectedStores?.length} stores`}?
                  </Text>
                  <Flex justifyContent={"center"} my="1.3rem">
                    <PrimaryButton
                      onClick={approveEmployee}
                      text="Approve"
                      width="150px"
                      padding="1rem !important"
                      loading={loadingx}
                    />
                  </Flex>
                </>
              )}
              {modalType === "suspend" && (
                <>
                  <Text
                    textAlign={"center"}
                    color={"#475467"}
                    fontWeight={"500"}
                  >
                    Confirm suspension of {selectedUser?.name}?
                  </Text>
                  <Flex justifyContent={"center"} my="1.3rem">
                    <PrimaryButton
                      onClick={suspendEmployee}
                      text="Suspend"
                      width="150px"
                      padding="1rem !important"
                      loading={loadingx}
                    />
                  </Flex>
                </>
              )}
              {modalType === "restore" && (
                <>
                  <Text
                    textAlign={"center"}
                    color={"#475467"}
                    fontWeight={"500"}
                  >
                    Confirm restore of {selectedUser?.name}?
                  </Text>
                  <Flex justifyContent={"center"} my="1.3rem">
                    <PrimaryButton
                      onClick={restoreEmployee}
                      text="Restore"
                      width="150px"
                      padding="1rem !important"
                      loading={loadingx}
                    />
                  </Flex>
                </>
              )}
              {modalType === "delete" && (
                <>
                  <Text
                    textAlign={"center"}
                    color={"#475467"}
                    fontWeight={"500"}
                  >
                    Confirm deletion of {selectedUser?.name || `${selectedStores?.length} stores`}?
                  </Text>
                  <Flex justifyContent={"center"} my="1.3rem">
                    <PrimaryButton
                      onClick={deleteEmployee}
                      text="Delete"
                      width="150px"
                      bg="crimson !important"
                      padding="1rem !important"
                      loading={loadingx}
                    />
                  </Flex>
                </>
              )}
              {modalType === "remove" && (
                <>
                  <Text
                    textAlign={"center"}
                    color={"#475467"}
                    fontWeight={"500"}
                  >
                    Confirm removal of {selectedUser?.name}?
                  </Text>
                  <Flex justifyContent={"center"} my="1.3rem">
                    <PrimaryButton
                      onClick={
                        currentUser?.role === "Agency"
                          ? removeEmployeex
                          : removeEmployee
                      }
                      text="Remove"
                      width="150px"
                      bg="crimson !important"
                      padding="1rem !important"
                      loading={loadingx}
                    />
                  </Flex>
                </>
              )}
              <Text
                pb="1rem"
                textAlign={"center"}
                fontSize={".9rem"}
                fontWeight={"500"}
                cursor={"pointer"}
                onClick={onClose}
              >
                Cancel
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal>

        <CustomPagination
          page={page}
          setPage={setPage}
          pageSize={limit}
          totalElements={total}
        />
      </Box>
    </>
  );
};

export default StoresTable;
