export const westAfricanCountries = [
    { name: 'Benin', code: 'BJ', flag: 'https://flagcdn.com/w40/bj.png' },
    { name: 'Burkina Faso', code: 'BF', flag: 'https://flagcdn.com/w40/bf.png' },
    { name: 'Cape Verde', code: 'CV', flag: 'https://flagcdn.com/w40/cv.png' },
    { name: 'Côte d\'Ivoire', code: 'CI', flag: 'https://flagcdn.com/w40/ci.png' },
    { name: 'Gambia', code: 'GM', flag: 'https://flagcdn.com/w40/gm.png' },
    { name: 'Ghana', code: 'GH', flag: 'https://flagcdn.com/w40/gh.png' },
    { name: 'Guinea', code: 'GN', flag: 'https://flagcdn.com/w40/gn.png' },
    { name: 'Guinea-Bissau', code: 'GW', flag: 'https://flagcdn.com/w40/gw.png' },
    { name: 'Liberia', code: 'LR', flag: 'https://flagcdn.com/w40/lr.png' },
    { name: 'Mali', code: 'ML', flag: 'https://flagcdn.com/w40/ml.png' },
    { name: 'Mauritania', code: 'MR', flag: 'https://flagcdn.com/w40/mr.png' },
    { name: 'Niger', code: 'NE', flag: 'https://flagcdn.com/w40/ne.png' },
    { name: 'Nigeria', code: 'NG', flag: 'https://flagcdn.com/w40/ng.png' },
    { name: 'Senegal', code: 'SN', flag: 'https://flagcdn.com/w40/sn.png' },
    { name: 'Sierra Leone', code: 'SL', flag: 'https://flagcdn.com/w40/sl.png' },
    { name: 'Togo', code: 'TG', flag: 'https://flagcdn.com/w40/tg.png' }
  ];
  