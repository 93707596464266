import { Avatar, Box, Flex, Text } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import User from "../../assets/user2.png";
import { useSelector } from 'react-redux';
import Notifications from '../notifications/notifications';
import classes from './navbar.module.css'
import { westAfricanCountries } from 'constants/detailedCountriesArray';
import UtilityContext from 'context/utilityContext';
import { IoIosArrowDropdown } from 'react-icons/io';

const Navbar = ({ collapsed }) => {
  const { pathname } = useLocation();
  const currentUser = useSelector(({ userData }) => userData.currentUser);

  const navigate = useNavigate()


  const { currentCountry, setCurrentCountry } = useContext(UtilityContext)

  const path = window.location.pathname

  const countryObject = westAfricanCountries?.find((item) => item?.name === currentCountry)

  return (
    <div className={classes.container}>

      <p className={classes.activeScreenText}> {pathname === '/' ? "Overview" : pathname.split('/')[1]?.replaceAll('/', '')}</p>

      {
        (currentUser?.role?.includes('Admin') || currentUser?.role?.includes('Super Admin')) && path !== '/tech-support-tickets' &&
        <p className={classes.tickets} style={{ cursor: 'pointer' }} onClick={() => navigate('/tech-support-tickets')} fontWeight={"500"} fontSize={".9rem"}>TS Tickets</p>
      }

      <Notifications />

      <div className={classes.avatarContainer}>
        <Avatar className={classes.avatar} cursor={"pointer"} onClick={() => { }} size={"md"} transform={"scale(.85)"} border={"1.73913px solid #CFD0D3"} src={currentUser?.imgUrl || User} />
        <div>
          <p className={classes.name} fontWeight={"500"} fontSize={".9rem"}>{currentUser?.role?.includes('Admin') ? (currentUser?.firstName) : currentUser?.role === "Agency" ? currentUser?.name : currentUser?.role === "Basic Subuser" ? currentUser?.name : currentUser?.companyName}</p>
          <p className={classes.role} color="#667085" fontSize={".9rem"}>{currentUser?.role}</p>
        </div>
      </div>






      {
        countryObject &&
        <div className={classes.dropDownGroup}>
          <div className={classes.dropDownSubGroup}>
            <img src={countryObject?.flag} alt={`${countryObject?.name} flag`} className={classes.flagImage} />
            <p className={classes.countryCode}>{countryObject?.code}</p>

            <IoIosArrowDropdown />
          </div>

          <div className={classes.countriesList}>
            {
              westAfricanCountries?.map((item, index) => (
                <div style={currentCountry === item?.name ? { background: '#000', color: '#fff' } : {}} onClick={() => { setCurrentCountry(item.name) }} className={classes.flexCountry} key={index}>
                  <img className={classes.flagImage} src={item.flag} alt={`${item.name} flag`} />
                  <p className={classes.countryCode}>{item?.name}</p>
                </div>
              ))

            }
          </div>
        </div>
      }


    </div>
  )
}

export default Navbar;